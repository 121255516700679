import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "A React image component with a fallback url",
  "author": "Patrick Passarella",
  "date": "2020-05-05",
  "subtitle": "And how to test it with Jest and React Testing Library",
  "cover": "./cover.jpg",
  "coverCredit": "NeONBRAND",
  "coverWebsite": "Unsplash",
  "published": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I am sure that you already found a website with an image not loading, maybe you don't really cared that much, but a lot of users care and find it very irritating. It's something incredible simple to solve, that adds a solid value to your application/website.`}</p>
    <p>{`What we will build, is a image component that can handle broken images, by having a fallback URL.
To start off, let's build a simple Image React component with Typescript.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`interface Props {
  url: string;
};

const Image: React.FC<Props> = ({url, ...rest}) => (
  <img src={url} {...rest} />
);

`}</code></pre>
    <p>{`As you can see, it's just a component that returns a `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag, now to add the fallback url we can use the property `}<inlineCode parentName="p">{`onError`}</inlineCode>{` that the `}<inlineCode parentName="p">{`img`}</inlineCode>{` tag has and almost no one knows about it.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`interface Props {
  url: string;
  fallback?: string;
};

const handleImageError = fallback => event => event.target.src = fallback;

const Image: React.FC<Props> = ({url, fallback, ...rest}) => (
  <img src={url} onError={handleImageError(fallback)} {...rest} />
);

`}</code></pre>
    <p>{`So if the main url fails, it will change the `}<inlineCode parentName="p">{`src`}</inlineCode>{` from the image to the passed fallback url, which could be for example a default image. And it works! you can already try it, check the gif below on how to manually test it.`}</p>
    <p><img parentName="p" {...{
        "src": "/3c20cb55dfa0de7920959a8c8a4c980c/fallback-image.gif",
        "alt": "gif"
      }}></img></p>
    <p>{`Just change the URL to something non-existent and you will see that it will change the image source to the fallback one.`}<br parentName="p"></br>{`
`}{`Now, how to test it with Jest? After long and painful hours of trying to test it properly, I found (with the help of the community), a not-too-bad solution, it's not the best, but at least we can test it properly.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import '@testing-library/jest-dom/extend-expect';
import { render, fireEvent, screen } from '@testing-library/react';
import Image from '..';

const imageUrl = 'https://images.unsplash.com/photo-1531436107035-40b2e85b7a1b?ixlib=rb-1.2.1&amp;ixid=eyJhcHBfaWQiOjEyMDd9&amp;w=1000&amp;q=80';
const fallbackUrl = 'https://images.unsplash.com/photo-1549880338-65ddcdfd017b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=750&q=80';
const imageErrorUrl = 'error://test';

describe('Image', () => {
  it('should render the fallback image if the main one has an error', () => {
    render(
      <Image
        src={imageErrorUrl}
        fallback={fallbackUrl}
      />
    );

    const image = screen.getByRole("img");
    expect(image).toHaveAttribute('src', imageErrorUrl);
    fireEvent.error(image); // Here we trigger the error event
    expect(image).toHaveAttribute('src', fallbackUrl); // and check if the src changed
  });
});

`}</code></pre>
    <p>{`Remembering we need the `}<inlineCode parentName="p">{`@testing-library/jest-dom/extend-expect`}</inlineCode>{` to be able to use the `}<inlineCode parentName="p">{`toHaveAttribute`}</inlineCode>{` property.
It's a simple test, we are getting the image tag, forcing an error event to happen there, and checking if the image `}<inlineCode parentName="p">{`src`}</inlineCode>{` has changed to the `}<inlineCode parentName="p">{`fallbackUrl`}</inlineCode>{`.`}</p>
    <p>{`That's it! now you have a simple image component with a fallback, that you can reuse it in your application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      